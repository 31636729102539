<template>
  <div class="entrance_box" v-show="environment!='micromessenger' || (environment=='micromessenger' && show)">

    <img v-if="environment=='alipay'" class="headImg" src="https://tzszntc.obs.cn-hcsozjtzzwy1.gyy.zjtzzwy.com:443/advertising%2F2022-06-01%2F153187263676901376069878.png" />
    <div v-if="environment=='micromessenger'">
      <wx-open-launch-weapp
        id="launch-btn-two"
        appid="wx6ed535eed08f080b"
        path="pages/index/index"
        style="width: 100vw;height: auto;display:block;"
      >
        <script v-is="'script'" type="text/wxtag-template">
          <style v-is="'style'">
            .headImg{
              width: 100vw;
              height: auto;
              display:block;
            }
          </style>
          <img class="headImg" src="https://tzszntc.obs.cn-hcsozjtzzwy1.gyy.zjtzzwy.com:443/advertising%2F2022-06-01%2F153187263676901376069878.png" />
        </script>
      </wx-open-launch-weapp> 
    </div>
    
    
    <div class="Contentbox">
      <div class="moneycontent">
        <text class="moneyfont">应收金额 (元)</text>
        <text class="money h3"> {{ (Number(infolist.shouldPay||0) + Number(totalCost||0)).toFixed(2) }}</text>
        <!-- <text class="money h3"> {{ infolist.shouldPay + '' + totalCost }}</text> -->
      </div>
      <div class="orderBox">
        <div class="orderinformation">
          <div class="content">
            <text class="contentleft">车牌号</text>
            <text class="contentright h4">{{ infolist.plateNo || "暂无" }}</text>
          </div>
          <div class="content">
            <text class="contentleft">泊车位</text>
            <text class="contentright">{{ infolist.entryNum || "暂无" }}</text>
          </div>
          
          <div class="content">
            <text class="contentleft">车辆类型</text>
            <text class="contentright">{{
              infolist.carType == 1
                ? "小型车"
                : infolist.carType == 2
                ? "大型车"
                : infolist.carType == 3
                ? "摩托车"
                : infolist.carType == 4
                ? "其他"
                : "公务车"
            }}</text>
          </div>
          <div class="content" v-if='infolist.orderBeginTime'>
            <div class="contentleft">进场时间</div>
            <div class="contentright">{{infolist.orderBeginTime}}</div>
          </div>
          <div class="content" v-if='infolist.orderEndTime'>
            <div class="contentleft">离场时间</div>
            <div class="contentright">{{infolist.orderEndTime}}</div>
          </div>
          <div v-if="OweFeeList.length>0">
            <div class="line first" @click="listShow=true">
              <text class="contentleft">历史欠费</text>
              <div class="contentright" @click="showList">
                <div class="shouldPay green">
                  {{ totalCost }}元
                </div>
                <img src="../assets/arrow_icon.png" alt="" class="">
              </div>
            </div>
            <div class="line">
              <text class="contentleft">本次费用</text>
              <div class="contentright red">
                <!-- {{ infolist.needPay ? infolist.shouldPay + '元' : '无需支付' }} -->
                {{  infolist.shouldPay!=0?(infolist.shouldPay||'0.00') + '元':'无需支付' }}
              </div>
            </div>
            <div class="line">
              <text class="contentleft">总费用</text>
              <div class="contentright red">
                {{(Number(infolist.shouldPay||0) + Number(totalCost||0)).toFixed(2)}}元
              </div>
            </div>
          </div>
          
        </div>
        <div
          class="orderinformation"
          v-for="(item, index) in infolist.subset"
          :key="index"
        >
          <div class="content xudan" v-if="infolist.billId != item.billId">
            <text class="xudan">续单</text>
          </div>
          <div class="content">
            <text class="contentleft">订单号</text>
            <text class="contentright">{{ item.billId }} &nbsp;</text>
          </div>
          <div class="content">
            <text class="contentleft">入场时间</text>
            <text class="contentright">{{ item.orderBeginTime }}</text>
          </div>
          <div class="content">
            <text class="contentleft">离场时间</text>
            <text class="contentright">{{ item.orderEndTime }}</text>
          </div>
          <div class="content">
            <text class="contentleft">停车时长</text>
            <text class="contentright">{{ item.carStopTime }}</text>
          </div>
          <div class="content">
            <text class="contentleft">订单状态</text>
            <text class="contentright">{{ item.orderStateStr }}</text>
          </div>
        </div>
      </div>
    </div>
    <!-- <img class="entrance_image" src="@/assets/entrance.png" alt /> -->
    <!-- <div class="topBtnBox" v-if="Number(infolist.shouldPay)>0 && infolist.needPay"> -->
    <div class="topBtnBox" v-if="Number(infolist.shouldPay)>0">
      <div class="payBtn" @click="payCurrentOrder">本次直接支付</div>
      <div class="payBtn appletBtn" style="margin-left: 10px;" v-if="environment=='alipay'" @click="alipay">本次会员支付</div>
      <div v-if="environment=='micromessenger'">
        <wx-open-launch-weapp
            id="launch-btn"
            username="gh_91d425b0b120"
            :path="'/pages/entrance/entrance.html?' + pageUrl.split('?')[1].split('#/')[0]"
            style="width: 50vw; height: 22vw;display:block; text-align: center; font-size: 18px;"
        >
            <script v-is="'script'" type="text/wxtag-template">
              <style v-is="'style'">
                  .btn{
                    border: none;
                    margin-top: 16vw;
                    margin-left: 16vw;
                    width: 84%;..
                    height: 23.6vw;
                    background-image: linear-gradient(51deg, #1991A3 0%, #7CB630 100%);
                    border-radius: 11.8vw;
                    font-family: PingFangSC-Medium;
                    font-weight: 500;
                    font-size: 9.6vw;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 23.6vw;
                  }
              </style>
              <button class="btn">本次会员支付</button>
            </script>
        </wx-open-launch-weapp>             
      </div>
    </div>
    
    <div class="payBtn" v-if="OweFeeList.length>0 && totalCost>0" @click="totalExpensePayment">总费用直接支付</div> 

    <div class="OweFeeList" v-if="listShow" @click.self="listShow=false">
      <img src="../assets/search_b.png" class="close"  @click.self="listShow=false" alt="">
      <div class="list">
        <div class="itemBox">
          <div :class="data.ischecked ? 'item bordercolor' :'item content'" @click="selectItem(data)" v-for="(data,index) in OweFeeList" :key="index">
            <div :class="data.ischecked ? 'radioClass' : 'radioHover'"></div>
            <div class="carcontent">
              <!-- <div class="plateNo">车牌号：{{ data.plateNo||'暂无' }}</div> -->
              <div class="shouldPayfont red">欠缴：{{ data.shouldPay }}元</div>
              <!-- <div class="orderBeginTime">泊位号：{{ data.entryNum||'暂无' }}</div> -->
              <div class="orderBeginTime">
                {{ data.entryGateNumName||'暂无' }}
                <!-- {{ data.entryNum||'暂无' }} -->
              </div>
              <div class="orderBeginTime">{{ data.orderBeginTime||'暂无' }}至{{ data.orderEndTime||'暂无' }}</div>
              <!-- <div class="orderBeginTime">离场时间：{{ data.orderEndTime||'暂无' }}</div> -->
              <!-- <div class="orderBeginTime">车辆类型：{{ data.carType||'暂无' }}</div> -->
              <div class="orderBeginTime">停车时长：{{ data.carStopTime||'暂无' }}</div>
            </div>
          </div>
        </div>
        
        
      </div>
      <div class="bottomLine">
        <van-checkbox checked-color="#3c987b" v-model="selectAll" @click="checkAll()">全选</van-checkbox>
        <div class="span bottomLineText">
          数量：{{sum}}
        </div>
        <div class="span bottomLineText">
          合计：{{totalCost}}
        </div>
        <div class="btn" @click="listShow=false">
          确定
        </div>
      </div>
    </div>
  </div>
</template>  

<script>
import { onMounted, reactive, toRefs } from "vue";
import wx from "weixin-js-sdk";
// import { useRouter } from "vue-router";
  
import { getOrderByRecordId, getH5WxLoginSign, createUserPayLinkDirectPay,overDueListByBerthNum } from "@/server/api.js";
import { Toast,Dialog } from "vant";
export default {
  setup() {
    const allData = reactive({
      pathurl: "",
      environment: '',
      pageUrl: '',
      urlInfo: {},
      infolist: {},
      show:false,
      OweFeeList:[
      ],
      selectAll: true,
      listShow: false,
      totalCost: 0,
      sum: 0,
      selectBillId: []
    });
    // const router = useRouter();
    onMounted(() => {
      // 判断当前环境
      allData.environment = isWeixin();
      if (!allData.environment ) {
        Toast("请在微信或支付宝环境下打开此页面");
        if (process.env.NODE_ENV!='development' && !window.location.href.includes('testwx')) {
          return false;
        }
      }
      
      allData.pageUrl = localStorage.getItem("durpUrlcenter");
      allData.urlInfo = getUrl(allData.pageUrl)
      // var urlParameter = getUrl(allData.pageUrl);
      // console.log(urlParameter);
      getOrderByRecordId({recordId: allData.urlInfo.recordId}).then((res) => {
        if (res.code==200) {
          if (!res.data.shouldPay) {
            Toast(res.data)
            return
          }
          allData.infolist=res.data
          let subset = JSON.parse(JSON.stringify(allData.infolist.subset))
          allData.subset = JSON.parse(JSON.stringify(allData.infolist.subset))
          allData.infolist.subset=[]
          subset?subset.forEach((item)=>{
            if (item.billId!=allData.infolist.billId) {
              allData.infolist.subset.push(item)
            }
          }):''
          // if (Number(res.data.shouldPay)==0) {
          //   Dialog.alert({
          //     title: '0元订单无需支付',
          //     message: '请尽快离场',
          //   }).then(() => {
          //     // on close
          //   });
          // }
          // needPay为false时，此次订单无需支付
          // if (!res.data.needPay) {
          //   res.data.shouldPay = '0.00'
          // }
          // 有车牌号获取欠费信息
          if (res.data.plateNo) {
            getOweList(res.data.plateNo,res.data.entryNum)
          }
          // let billList = []
          // let codeBill = res.data.billId
          // res.data.subset.forEach((item) => {
          //   billList.push(item.billId)
          // });
          
        }else{
          if (res.data) {
            allData.infolist=res.data
            getOweList(res.data.plateNo,res.data.entryNum)
          } else {
            Toast({
              message:res.msg,
              duration:60000
            })
          }
          
        }
        
      })
      if (allData.environment=="micromessenger") {
        // 微信公众号获取签名
        allData.pathurl = location.href.split("#")[0];
        getH5WxLoginSign({ url: allData.pathurl }).then((res) => {
          console.log(res);
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.data.appId, // 必填，公众号的唯一标识 gh_298d2d1bf9ee   wx3d385eb9ae7f6482
            timestamp: res.data.timestamp.toString(), // 必填，生成签名的时间戳
            nonceStr: res.data.noncestr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名
            jsApiList: [
              "openTagList",
              "onMenuShareTimeline",
              "onMenuShareAppMessage",
              "onMenuShareQQ",
              "onMenuShareQZone",
              "hideOptionMenu",
            ], //必填，需要使用的JS接口列表1
            openTagList: ["wx-open-launch-weapp"], // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
          });
          // 处理成功验证
          wx.ready(function () {
            setTimeout(()=>{
              allData.show=true
              document.getElementById("launch-btn").style.height="20vw"
              // document.getElementById("launch-btn-two").style.height="20vw"
            },200)
            console.log("wx.ready-success");
            // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中
          });
          // 处理失败验证
          wx.error(function (res) {
            console.log(res);
            console.log("error fail");
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
          });
          var btn = document.getElementById("launch-btn");
          btn.addEventListener("launch", function (e) {
            console.log("success", e);
          });
          btn.addEventListener("error", function (e) {
            console.log("fail", e.detail);
          });

          var btnTwo = document.getElementById("launch-btn-two");
          btnTwo.addEventListener("launch", function (e) {
            console.log("success", e);
          });
          btnTwo.addEventListener("error", function (e) {
            console.log("fail", e.detail);
          });
        });
      }
    });
    // 获取url路径中?后面的参数
    const getUrl = (urlStr) => {
      var url;
      if (typeof urlStr == "undefined") {
        url = decodeURI(location.search); //获取url中"?"符后的字符串
      } else {
        url = "?" + urlStr.split("?")[1];
      }
      var theRequest = new Object();
      if (url.indexOf("?") != -1) {
        var str = url.substr(1);
        var strs = str.split("&");
        for (var i = 0; i < strs.length; i++) {
          theRequest[strs[i].split("=")[0]] = decodeURI(strs[i].split("=")[1]);
        }
      }
      return theRequest;
    };

    // 判断当前浏览器是不是微信浏览器
    const isWeixin = () => {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return "micromessenger";
      } else if (ua.match(/Alipay/i) == "alipay") {
        return "alipay";
      } else {
        return false;
      }
    };
    
    const alipay = () => {
      window.location.href = "alipays://platformapi/startapp?appId=2021002170682296&page=pages/load/load&query="
        +encodeURIComponent(allData.pageUrl.split("?")[1])
        // +encodeURIComponent("billId=158479096225191116876277&recordId=1584790962004066305&parktype=roadParkFee")
      // window.location.href = "alipays://platformapi/startapp?appId=2021002170682296&page=pages/index/index?billId=163764512766337433637891&recordId=1478619602157707265&parktype=roadParkFee"
    };
    const payCurrentOrder = () => {
      getOrderByRecordId({recordId: allData.urlInfo.recordId}).then((res) => {
        if (res.code==200) {
          // let billList = []
          let codeBill = res.data.billId
          // 管理员标识
          let printer = allData.urlInfo.printer?allData.urlInfo.printer:""
          // console.log(allData.urlInfo);
          printer.indexOf("#/")!=-1?printer=printer.split("#")[0]:''
          // res.data.subset.forEach((item) => {
          //   billList.push(item.billId)
          // });
          let billIds = []
          res.data.subset.forEach(item => {
            billIds.push(item.billId)
          });
          let form = {
            "jpushId": '',
            "billId": codeBill,
            // 'billIds': billList.join(','),
            'billIds': billIds.join(","),
            'type': 'ALI_QR_CODE_PAY',
            'isPadSettlement':true,
            'printer':printer
          }
          createUserPayLinkDirectPay(form).then((res1)=>{
            if (res1.code==200) {
              // debugger;
              window.location.href=res1.data.qrCode
            } else {
              Toast(res1.msg)
            }
          })
        }else{
          Toast({
            message:res.msg,
            duration:60000
          })
        }
        
      })
      
    }
    const totalExpensePayment = () => {
      let printer = allData.urlInfo.printer
      printer.indexOf("#/")!=-1?printer=printer.split("#")[0]:''
      let billIdList=[]
      allData.subset?.forEach(item=>{
        billIdList.push(item.billId)
      })
      let form = {
        "jpushId":"",
        "billId": allData.infolist.billId || allData.selectBillId[0],
        "billIds": allData.selectBillId.join(",")+","+billIdList.join(","),
        'type': 'ALI_QR_CODE_PAY',
        'isPadSettlement':true,
        "payStatus":false,
        'printer':printer
      }
      createUserPayLinkDirectPay(form).then((res) => {
        if (res.code==200) {
              // debugger;
          window.location.href=res.data.qrCode
        } else {
          Toast({
            message:res.msg,
            duration:60000
          })
        }
      })
    }
    const selectItem = (item) => {
      item.ischecked = !item.ischecked
      var sum = 0
      var totalCost = 0
      allData.selectBillId = []
      allData.OweFeeList.forEach((i)=>{
        // i.ischecked?sum++:''
        if (i.ischecked) {
          sum++
          totalCost = totalCost + i.shouldPay
          allData.selectBillId.push(i.billId)
        }
      })
      if (sum==allData.OweFeeList.length) {
        allData.selectAll=true
      }else{
        allData.selectAll=false
      }
      allData.sum = sum
      allData.totalCost = totalCost.toFixed(2)
    }
    const checkAll = () => {
      allData.totalCost=0
      allData.sum=0
      allData.selectBillId=[]
      allData.OweFeeList.forEach((i)=>{
        i.ischecked=allData.selectAll
        if (allData.selectAll) {
          allData.totalCost=allData.totalCost+i.shouldPay
          allData.selectBillId.push(i.billId)
        }
      })
      if (allData.selectAll) {
        allData.sum = allData.OweFeeList.length
      }
      allData.totalCost=allData.totalCost.toFixed(2)
    }
    const getOweList = (plateNo, entryNum)=>{
      overDueListByBerthNum({
        berthCode:entryNum,
        plateNo:plateNo,
        pageSize: 1000,
      }).then((res)=>{
        if (res.code==200 && res.data.records.length>0) {
          allData.OweFeeList=[]
          var records = []
          console.log(records);
          console.log(allData.infolist.subset,allData.infolist.billId,res.data.records);
          for (let index = 0; index < res.data.records.length; index++) {
            console.log(index);
            var repeat =false
            if (res.data.records[index].billId==allData.infolist.billId) {
              repeat = true
            }
            if (allData.infolist.subset && allData.infolist.subset.length>0) {
              //排除了主单的续费
              allData.infolist.subset.forEach((i)=>{//遍历续费订单 i.billId  
                //欠费Id等于本单Id或欠费Id等于续单Id
                if(res.data.records[index].billId==i.billId || res.data.records[index].billId==allData.infolist.billId){
                  repeat = true
                }
              })
            }
            if (!repeat) {
              records.push(res.data.records[index])
            }
          }
          allData.OweFeeList=records
          //全选
          checkAll()
        }
        // if(this.infolist.subset.length>1){
        //   this.infolist.subset.forEach((item, index, array)=>{
        //     res.data
        //   })
        // }
        // 无欠费记录且应付为0
        console.log(allData.infolist.shouldPay);
        if (res.data.records.length==0 && !allData.infolist.shouldPay) {
            Dialog.alert({
              title: '0元订单无需支付',
              message: '请尽快离场',
            }).then(() => {
              // on close
            });
        }
        if (res.code!=200) {
          Toast(res.code)
        }
      })
    }
    return {
      ...toRefs(allData),
      alipay,
      payCurrentOrder,
      selectItem,
      checkAll,
      getOweList,
      totalExpensePayment
    };
  },
};
</script>

<style scoped>
.entrance_box {
  width: 100vw;
  height: auto;
  padding-bottom: 6vw;
  display: flex;
  flex-direction: column;
  align-items: center;
}
.entrance_image {
  margin-top: 20vh;
  width: 80%;
  height: 180px;
}
.payBtn {
  margin-top: 8vw;
  width:92vw;
  height: 11.8vw;
  background-image: linear-gradient(51deg, #1991a3 0%, #7cb630 100%);
  border-radius: 5.9vw;
  line-height: 11.8vw;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  font-size: 4.8vw;
  color: #ffffff;
  text-align: center;
}
.entrance_box {
  width: 100vw;
}
.entrance_box .Contentbox{
  width: 100%;
}
.entrance_box .Contentbox .moneycontent {
  height: 83px;
  margin-top: 8px;
  background: #ffffff;
  box-shadow: 0px 3px 2px 0px #e6ebf0;
  /* overflow: hidden; */
}
.entrance_box .Contentbox .moneycontent .moneyfont {
  display: block;
  margin-top: 30px;
  font-size: 16px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: center;
  color: #363a44;
}
.entrance_box .Contentbox .moneycontent .money {
  display: block;
  margin-top: 8px;
  font-size: 16px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  color: #1c929f;
}
.entrance_box .Contentbox .orderBox {
  height: auto;
}
.entrance_box .Contentbox .orderinformation {
  height: auto;
  margin-top: 8px;
  padding: 16px;
  background: #ffffff;
  box-shadow: 0px 3px 2px 0px #e6ebf0;
}
.entrance_box .Contentbox .orderinformation .content,
.entrance_box .Contentbox .orderinformation .contentbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 28px;
}
.entrance_box .Contentbox .orderinformation .content .contentleft,
.entrance_box .Contentbox .orderinformation .contentbox .contentleft,
.entrance_box .Contentbox .orderinformation .content .contentright,
.entrance_box .Contentbox .orderinformation .contentbox .contentright {
  display: flex;
  align-items: center;
  width: 40%;
  font-size: 16px;
  font-weight: 400;
  text-align: left;
  color: #363a44;
}
.entrance_box .Contentbox .orderinformation .content .contentright,
.entrance_box .Contentbox .orderinformation .contentbox .contentright {
  word-wrap: break-word;
  text-align: right;
  display: inline-block;
  width: 80%;
  color: #686B73;
  font-size: 14px;
}
.entrance_box .Contentbox .orderinformation .content .xudan,
.entrance_box .Contentbox .orderinformation .contentbox .xudan {
  display: inline-block;
  width: 100%;
  color: red;
  text-align: right;
  font-size: 14px;
  height: 15px;
  position: relative;
  top: -10px;
}
.entrance_box .zhifu {
  width: 100%;
  height: 66px;
}
.entrance_box .zhifu .casePay {
  background: #53C413 !important;
}
.LineargradientBtn{
		display: block;
		text-align: center;
		width: 90%;
		margin: 50px auto;
		height: 48px;
		line-height: 48px;
		background: #53c413;
		border-radius: 24px;
		color: #fff;
		font-size: 18px;
		font-weight: 500;
		background: linear-gradient(50deg,#1991a3 5%, #7cb630 100%);
	}
	.LineargradientBtn{
		color: #fff ;
			background: linear-gradient(50deg,#1991a3 5%, #7cb630 100%);
	}
  .casePay {
    background: #53C413 !important;
  }
  .prompt {
    margin: 0 auto;
    color: #686B73;
    font-size: 16px;
    text-align: center;
  }
  .h3 {
    font-size: 24px !important;
  }
  .h4 {
    font-size: 20px !important;
  }
  .headImg {
    width: 100vw;
    height: auto;
  }
  .entrance_box .Contentbox {
    border-radius: 15px 15px 0 0;
    box-shadow: 0 -1px 0 0 #EAEAEA, 0 -5px 20px 0 rgba(200, 200, 200, 0.5);
    background: #fff;
    overflow: hidden;
    position: relative;
    top: -10px;
  }
  .line {
    display: flex;
    justify-content: space-between;
    height: 30px;
    line-height: 30px;
    /* padding: 0 16px; */
    font-size: 14px;
    position: relative;
  }
  .line .contentleft{
    color: #363a44;
    font-size: 16px;
  }
  .line .contentright{
    display: flex;
    color: #363a44;
    justify-content:flex-end;
    font-size: 16px;
    align-items: center;
  }
  .line .contentright img{
    width: 20px;
    height: 20px;
    /* position: absolute;
    right: 0px; */
  }
  .green {
    color: #7CB630;
    font-size: 16px;
  }
  .red {
    font-size: 18px;
    color: #ff0000 !important;
  }
  .OweFeeList {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
}
.OweFeeList .close{
  float: right;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  top: 16.5vh;
  z-index: 1;
}
.OweFeeList .list {
  width: 100%;
  height: 85vh;
  position: relative;
  top: 15vh;
  background: #fff;
  border-radius: 15px 15px 0 0;
  padding-top: 10px;
}
.OweFeeList .list .item {
  width: 94%;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0vw 2.833333vw 0px #e6ebf0;
  margin-left: 3%;
  padding: 10px;
  box-sizing: border-box;
}
.OweFeeList .list .bordercolor, .OweFeeList .list .content{
  display: flex;
  align-items: center;
  box-sizing: border-box;
  /* justify-content: space-between; */
}
.bordercolor {
  border: 1px solid rgb(60,152,123);
}
.content {
  border: 1px solid #fff;
}
.item .radioHover, .item .radioClass{
  width: 8px;
  height: 8px;
  margin-right: 10px;
  display: block;
  border-radius: 50%;
  background: #000;
}
.item .radioHover {
  background-color: #aaa;
}
.item .radioClass {
  background: rgb(60,152,123);
}
.item .carcontent {
  width: 90%;
  display: flex;
  flex-direction: column;
}
.OweFeeList .list .item {
  font-size: 14px;
  margin-bottom: 10px;
}
.OweFeeList .list .item .red {
  font-size: 14px;
}
.OweFeeList .bottomLine {
  width: 100vw;
  height: 60px;
  display: flex;
  background: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  position: absolute;
  bottom: 0px;
  box-sizing: border-box;
  box-shadow: 0px 0vw 2.833333vw 0px #e6ebf0;
}
.OweFeeList .btn {
  width: 14vw;
  background-color: rgb(60,152,123);
  height: 8vw;
  line-height: 8vw;
  border-radius: 4vw;
  text-align: center;
  color: #fff;
  font-size: 4vw;
}
.OweFeeList .itemBox {
  margin-top: 4vh;
  padding-top: 1vh;
  box-sizing: border-box;
  padding-bottom: 1vh;
  height: 73vh;
  overflow: scroll;
  width: 100vw;
}
.bottomLineText {
  color: #333;
}
/* .first{
    margin-top: 10px;
  } */
.first .contentright {
  position: relative;
  right: -5px;
}
.topBtnBox {
  display: flex;
  justify-content: space-between;
  width: 92%;
}
.topBtnBox .payBtn {
  width: 100%;
}
</style>