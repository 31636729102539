<template>
  <div class="MainBox">

    <img id="myImage" class="headImg" src="https://tzszntc.obs.cn-hcsozjtzzwy1.gyy.zjtzzwy.com:443/advertising%2F2022-06-01%2F153187263676901376069878.png" />
    <div class="Contentbox">
      <div class="moneycontent">
        <text class="moneyfont">应收金额 (元)</text>
        <text class="money h3">{{ infolist.shouldPay }}</text>
      </div>
      <div class="orderBox">
        <div class="orderinformation">
          <!-- <div class="content">
            <text class="contentleft">岗位</text>
            <text class="contentright">{{
              infolist.entryGateNumName || "暂无"
            }}</text>
          </div> -->
          <div class="content">
            <text class="contentleft">泊车位</text>
            <text class="contentright">{{ infolist.entryNum || "暂无" }}</text>
          </div>
          <div class="content">
            <text class="contentleft">车牌号</text>
            <text class="contentright h4">{{ infolist.plateNo || "暂无" }}</text>
          </div>
          <div class="content">
            <text class="contentleft">车辆类型</text>
            <text class="contentright">{{
              infolist.carType == 1
                ? "小型车"
                : infolist.carType == 2
                ? "大型车"
                : infolist.carType == 3
                ? "摩托车"
                : infolist.carType == 4
                ? "其他"
                : "公务车"
            }}</text>
          </div>
        </div>
        <div
          class="orderinformation"
          v-for="(item, index) in infolist.subset"
          :key="index"
        >
          <div class="content xudan" v-if="infolist.billId != item.billId">
            <div></div>
            <div class="xudan">续单</div>
            
          </div>
          <div class="content">
            <text class="contentleft">订单号</text>
            <text class="contentright">{{ item.billId }}</text>
          </div>
          <div class="content">
            <text class="contentleft">入场时间</text>
            <text class="contentright">{{ item.orderBeginTime }}</text>
          </div>
          <div class="content">
            <text class="contentleft">离场时间</text>
            <text class="contentright">{{ item.orderEndTime }}</text>
          </div>
          <div class="content">
            <text class="contentleft">停车时长</text>
            <text class="contentright">{{ item.carStopTime }}</text>
          </div>
          <div class="content">
            <text class="contentleft">订单状态</text>
            <text class="contentright">{{ item.orderStateStr }}</text>
          </div>
        </div>
        <!-- <div class="line first" @click="listShow=true">
          <text class="contentleft">历史欠费</text>
          <div class="contentright" @click="showList">
            <div class="shouldPay green">
              {{ totalCost }}元
            </div>
            <img src="../assets/arrow_icon.png" alt="" class="">
          </div>
        </div>
        <div class="line">
          <text class="contentleft">本次费用</text>
          <div class="contentright red">
            {{ infolist.shouldPay }}元
          </div>
        </div>
        <div class="line">
          <text class="contentleft">总费用</text>
          <div class="contentright red">
            {{ infolist.shouldPay + totalCost }}元
          </div>
        </div> -->
      </div>
    </div>
    <!-- 支付 -->
    <div class="zhifu">
      <text v-if="infolist.shouldPay * 1 == 0" class="LineargradientBtn casePay"
        >0元账单无需支付</text
      >
      <text
        v-if="infolist.shouldPay != 0"
        class="payBtn"
        @click="prompt"
        >支付</text
      >
      <div class="payBtn appletBtn" v-if="environment=='alipay'" @click="alipay">本次会员支付</div>
      <div v-if="environment=='micromessenger'">
        <wx-open-launch-weapp
            id="launch-btn"
            username="gh_91d425b0b120"
            :path="'/pages/entrance/entrance.html?billId=' + infolist.billId + '&recordId=' + infolist.subset[0].serialNum + '&parktype=roadParkFee'"
            style="width: 100vw; height: 22vw;display:block; text-align: center; font-size: 18px;"
        >
            <script v-is="'script'" type="text/wxtag-template">
              <style v-is="'style'">
                  .btn{
                    border: none;
                    margin-top: 4vw;
                    margin-left: 4vw;
                    width: 92vw;
                    height: 11.8vw;
                    background-image: linear-gradient(51deg, #1991A3 0%, #7CB630 100%);
                    border-radius: 5.9vw;
                    font-family: PingFangSC-Medium;
                    font-weight: 500;
                    font-size: 4.8vw;
                    color: #FFFFFF;
                    text-align: center;
                    line-height: 11.8vw;
                  }
              </style>
              <button class="btn">本次会员支付</button>
            </script>
        </wx-open-launch-weapp>             
      </div>
    </div>
    <div class="prompt">
      提醒:车牌号不对请联系81896000结束订单
    </div>
    <div class="OweFeeList" v-if="listShow" @click.self="listShow=false">
      <img src="../assets/search_b.png" class="close"  @click.self="listShow=false" alt="">
      <div class="list">
        <div class="itemBox">
          <div :class="data.ischecked ? 'item bordercolor' :'item content'" @click="selectItem(data)" v-for="(data,index) in OweFeeList" :key="index">
            <div :class="data.ischecked ? 'radioClass' : 'radioHover'"></div>
            <div class="carcontent">
              <!-- <div class="plateNo">车牌号：{{ data.plateNo||'暂无' }}</div> -->
              <div class="shouldPayfont red">欠缴：{{ data.shouldPay }}元</div>
              <!-- <div class="orderBeginTime">泊位号：{{ data.entryNum||'暂无' }}</div> -->
              <div class="orderBeginTime">
                {{ data.entryGateNumName||'暂无' }}
                <!-- {{ data.entryNum||'暂无' }} -->
              </div>
              <div class="orderBeginTime">{{ data.orderBeginTime||'暂无' }}至{{ data.orderEndTime||'暂无' }}</div>
              <!-- <div class="orderBeginTime">离场时间：{{ data.orderEndTime||'暂无' }}</div> -->
              <!-- <div class="orderBeginTime">车辆类型：{{ data.carType||'暂无' }}</div> -->
              <div class="orderBeginTime">停车时长：{{ data.carStopTime||'暂无' }}</div>
            </div>
          </div>
        </div>
        
        
      </div>
      <div class="bottomLine">
        <van-checkbox v-model="selectAll" @click="checkAll()">全选</van-checkbox>
        <div class="span bottomLineText">
          数量：{{sum}}
        </div>
        <div class="span bottomLineText">
          合计：{{totalCost}}
        </div>
        <div class="btn">
          确定
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { reactive, toRefs, onMounted, createApp } from "vue";
import { useRoute } from "vue-router";
import { createUserPayLink,getH5WxLoginSign } from "@/server/api.js";
// import wx from "weixin-js-sdk";
import wx from "weixin-js-sdk";
import { Dialog, Checkbox } from 'vant';
const app = createApp();
app.use(Checkbox);
export default {
  setup() {
    
    const allData = reactive({
      // berthNum: [],
      // isShow: false,
      // isShowUser: false,
      // isclick: false,
      // isExceed: false,
      // errMsgtext: "", //弹框提示
      // iphone: "",

      infolist: {},
      totalCost: 0,
      sum: 0,
      OweFeeList:[
      
      ],
      selectAll: false,
      listShow: false,
      environment: ""
    });
    const router = useRoute();
    let berthCode = localStorage.getItem("berthCode")
    
    const prompt = () => {
      Dialog.confirm({
        title: '提示',
        message: `是否对${allData.infolist.plateNo}进行缴费`,
      }).then(() => {
          // on confirm
        codepayment()
      }).catch(() => {
        // on cancel
      });
    }
    const codepayment = () => {
      let billIdList = []
      allData.infolist.subset.forEach((item) => {
        if (item.orderState==1 || item.orderState==3) {
          billIdList.push(item.billId)
        }
      });
      let form = {
        "jpushId": '',
        'billIds': billIdList.join(','),
        'type': 'ALI_QR_CODE_PAY',
        "berthCode": berthCode,
        'isPadSettlement':true
      }
      createUserPayLink(form).then((res)=>{
        window.location.href=res.data.qrCode
      })
    }
    const selectItem = (item) => {
      item.ischecked = !item.ischecked
      var sum = 0
      var totalCost = 0
      allData.OweFeeList.forEach((i)=>{
        // i.ischecked?sum++:''
        if (i.ischecked) {
          sum++
          totalCost = totalCost + i.shouldPay
        }
      })
      if (sum==allData.OweFeeList.length) {
        allData.selectAll=true
      }else{
        allData.selectAll=false
      }
      allData.sum = sum
      allData.totalCost = totalCost.toFixed(2)
    }
    const checkAll = () => {
      allData.totalCost=0
      allData.sum=0
      allData.OweFeeList.forEach((i)=>{
        i.ischecked=allData.selectAll
        if (allData.selectAll) {
          allData.totalCost=allData.totalCost+i.shouldPay
        }
      })
      if (allData.selectAll) {
        allData.sum = allData.OweFeeList.length
      }
      allData.totalCost=allData.totalCost.toFixed(2)
    }
    // 判断当前浏览器是不是微信浏览器
    const isWeixin = () => {
      var ua = navigator.userAgent.toLowerCase();
      if (ua.match(/MicroMessenger/i) == "micromessenger") {
        return "micromessenger";
      } else if (ua.match(/Alipay/i) == "alipay") {
        return "alipay";
      } else {
        return false;
      }
    };
    const alipay = () => {
      window.location.href = "alipays://platformapi/startapp?appId=2021002170682296&page=pages/load/load&query="
        +encodeURIComponent(`billId=${allData.infolist.billId}&recordId=${allData.infolist.subset[0].serialNum}&parktype=roadParkFee`)
        // +encodeURIComponent(allData.pageUrl.split("?")[1])
        // +encodeURIComponent("billId=158479096225191116876277&recordId=1584790962004066305&parktype=roadParkFee")
      // window.location.href = "alipays://platformapi/startapp?appId=2021002170682296&page=pages/index/index?billId=163764512766337433637891&recordId=1478619602157707265&parktype=roadParkFee"
    };
    onMounted(() => {
      allData.infolist = JSON.parse(router.query.data)
      console.log(allData.infolist);
      allData.environment = isWeixin();

      if (allData.environment=="micromessenger") {
        // 微信公众号获取签名
        allData.pathurl = location.href.split("#")[0];
        getH5WxLoginSign({ url: allData.pathurl }).then((res) => {
          console.log(res);
          wx.config({
            debug: false, // 开启调试模式,调用的所有api的返回值会在客户端alert出来，若要查看传入的参数，可以在pc端打开，参数信息会通过log打出，仅在pc端时才会打印。
            appId: res.data.appId, // 必填，公众号的唯一标识 gh_298d2d1bf9ee   wx3d385eb9ae7f6482
            timestamp: res.data.timestamp.toString(), // 必填，生成签名的时间戳
            nonceStr: res.data.noncestr, // 必填，生成签名的随机串
            signature: res.data.signature, // 必填，签名
            jsApiList: [
              "openTagList",
              "onMenuShareTimeline",
              "onMenuShareAppMessage",
              "onMenuShareQQ",
              "onMenuShareQZone",
              "hideOptionMenu",
            ], //必填，需要使用的JS接口列表1
            openTagList: ["wx-open-launch-weapp"], // 可选，需要使用的开放标签列表，例如['wx-open-launch-app']
          });
          // 处理成功验证
          wx.ready(function () {
            setTimeout(()=>{
              allData.show=true
              document.getElementById("launch-btn").style.height="20vw"
            },200)
            console.log("wx.ready-success");
            // config信息验证后会执行ready方法，所有接口调用都必须在config接口获得结果之后，config是一个客户端的异步操作，所以如果需要在页面加载时就调用相关接口，则须把相关接口放在ready函数中调用来确保正确执行。对于用户触发时才调用的接口，则可以直接调用，不需要放在ready函数中
          
            // 添加点击图片跳转到微信小程序的功能
            document.getElementById('myImage').addEventListener('click', function() {
              wx.navigateToMiniProgram({
                appId: 'wx6ed535eed08f080b', // 目标小程序的AppID
                path: 'pages/index/index', // 打开的页面路径
                // extraData: {
                //   foo: 'bar'
                // }, // 需要传递给小程序的数据
                // envVersion: 'release', // 小程序版本
                success(res) {
                  // 成功跳转后的回调函数
                  console.log('Success navigated to:', res);
                },
                fail(err) {
                  // 失败时的回调函数
                  console.error('Failed:', err);
                }
              });
            });

          
          });
          // 处理失败验证
          wx.error(function (res) {
            console.log(res);
            console.log("error fail");
            // config信息验证失败会执行error函数，如签名过期导致验证失败，具体错误信息可以打开config的debug模式查看，也可以在返回的res参数中查看，对于SPA可以在这里更新签名
          });
          var btn = document.getElementById("launch-btn");
          btn.addEventListener("launch", function (e) {
            console.log("success", e);
          });
          btn.addEventListener("error", function (e) {
            console.log("fail", e.detail);
          });
        });
      }
      // console.log(allData.infolist);
    });
    return {
      ...toRefs(allData),
      codepayment,
      prompt,
      selectItem,
      checkAll,
      alipay
    };
  },
};
</script>
<style scoped>
.MainBox {
  width: 100vw;
  height: 100vh;
}
.MainBox .Contentbox .moneycontent {
  height: 123px;
  margin-top: 8px;
  background: #ffffff;
  /* box-shadow: 0px 3px 2px 0px #e6ebf0; */
  overflow: hidden;
}
.MainBox .Contentbox {
  border-radius: 15px 15px 0 0;
  box-shadow: 0 -1px 0 0 #EAEAEA, 0 -5px 20px 0 rgba(200, 200, 200, 0.5);
  background: #fff;
  overflow: hidden;
  position: relative;
  top: -10px;
}
.MainBox .Contentbox .moneycontent .moneyfont {
  display: block;
  margin-top: 30px;
  font-size: 16px;
  font-family: PingFangSC, PingFangSC-Regular;
  font-weight: 400;
  text-align: center;
  color: #363a44;
}
.MainBox .Contentbox .moneycontent .money {
  display: block;
  margin-top: 8px;
  font-size: 16px;
  font-family: PingFangSC, PingFangSC-Medium;
  font-weight: 500;
  text-align: center;
  color: #1c929f;
}
.MainBox .Contentbox .orderBox {
  height: auto;
}
.MainBox .Contentbox .orderinformation {
  height: auto;
  /* margin-top: 2px; */
  padding: 10px 16px;
  background: #ffffff;
  /* box-shadow: 0px 3px 2px 0px #e6ebf0; */
  border-bottom:1px solid #EAEAEA;
}
.MainBox .Contentbox .orderinformation .content,
.MainBox .Contentbox .orderinformation .contentbox {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  line-height: 35px;
  height: 35px;
}
.MainBox .Contentbox .orderinformation .content .contentleft,
.MainBox .Contentbox .orderinformation .contentbox .contentleft,
.MainBox .Contentbox .orderinformation .content .contentright,
.MainBox .Contentbox .orderinformation .contentbox .contentright {
  display: flex;
  align-items: center;
  width: 40%;
  font-size: 14px;
  font-weight: 400;
  text-align: left;
  color: #686B73;
}
.MainBox .Contentbox .orderinformation .content .contentright,
.MainBox .Contentbox .orderinformation .contentbox .contentright {
  word-wrap: break-word;
  text-align: right;
  display: inline-block;
  width: 80%;
  color: #363a44;
  font-size: 14px;
}
.MainBox .Contentbox .orderinformation .xudan,
.MainBox .Contentbox .orderinformation .content .xudan,
.MainBox .Contentbox .orderinformation .contentbox .xudan {
  display: inline-block;
  width: 100%;
  color: red;
  text-align: right;
  font-size: 14px;
  height: 15px;
  position: relative;
  /* top: -10px; */
  line-height: 25px;
  height: 25px;
}
.MainBox .zhifu {
  width: 100%;
  /* height: 66px; */
}
.MainBox .zhifu .casePay {
  background: #53C413 !important;
}
.LineargradientBtn{
		display: block;
		text-align: center;
		width: 90%;
		margin: 50px auto 15px;
		height: 48px;
		line-height: 48px;
		background: #53c413;
		border-radius: 24px;
		color: #fff;
		font-size: 18px;
		font-weight: 500;
		background: linear-gradient(50deg,#1991a3 5%, #7cb630 100%);
	}
	.LineargradientBtn{
		color: #fff ;
			background: linear-gradient(50deg,#1991a3 5%, #7cb630 100%);
	}
  .casePay {
    background: #53C413 !important;
  }
  .prompt {
    margin: 0 auto;
    color: #686B73;
    font-size: 16px;
    text-align: center;
    margin-top: 5vw;
  }
  .h3 {
    font-size: 24px !important;
  }
  .h4 {
    font-size: 20px !important;
  }
  .headImg {
    width: 100vw;
    height: auto;
  }
  .first{
    margin-top: 10px;
  }
  .first .contentright {
    position: relative;
    right: -5px;
  }
  .line {
    display: flex;
    justify-content: space-between;
    height: 35px;
    line-height: 35px;
    padding: 0 16px;
    font-size: 14px;
    position: relative;
  }
  .line .contentleft{
    color: #686B73;
  }
  .line .contentright{
    display: flex;
    color: #363a44;
    justify-content:flex-end;
    align-items: center;
  }
  .line .contentright img{
    width: 20px;
    height: 20px;
    /* position: absolute;
    right: 0px; */
  }
  .green {
    color: #7CB630;
    font-size: 16px;
  }
  .red {
    font-size: 18px;
    color: #ff0000 !important;
  }
</style>
<style scoped >
.OweFeeList {
  width: 100vw;
  height: 100vh;
  background: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
}
.OweFeeList .close{
  float: right;
  width: 25px;
  height: 25px;
  margin-right: 10px;
  margin-bottom: 10px;
  position: relative;
  top: 130px;
  z-index: 1;
}
.OweFeeList .list {
  width: 100%;
  height: 85vh;
  position: relative;
  top: 15vh;
  background: #fff;
  border-radius: 15px 15px 0 0;
  padding-top: 10px;
}
.OweFeeList .list .item {
  width: 94%;
  border-radius: 5px;
  background: #fff;
  box-shadow: 0px 0vw 2.833333vw 0px #e6ebf0;
  margin-left: 3%;
  padding: 10px;
  box-sizing: border-box;
}
.OweFeeList .list .bordercolor, .OweFeeList .list .content{
  display: flex;
  align-items: center;
  box-sizing: border-box;
  /* justify-content: space-between; */
}
.bordercolor {
  border: 1px solid rgb(60,152,123);
}
.content {
  border: 1px solid #fff;
}
.item .radioHover, .item .radioClass{
  width: 8px;
  height: 8px;
  margin-right: 10px;
  display: block;
  border-radius: 50%;
  background: #000;
}
.item .radioHover {
  background-color: #aaa;
}
.item .radioClass {
  background: rgb(60,152,123);
}
.item .carcontent {
  width: 90%;
  display: flex;
  flex-direction: column;
}
.OweFeeList .list .item {
  font-size: 14px;
  margin-bottom: 10px;
}
.OweFeeList .list .item .red {
  font-size: 14px;
}
.OweFeeList .bottomLine {
  width: 100vw;
  height: 60px;
  display: flex;
  background: #fff;
  justify-content: space-between;
  align-items: center;
  padding: 0 10px;
  position: absolute;
  bottom: 0px;
  box-sizing: border-box;
  box-shadow: 0px 0vw 2.833333vw 0px #e6ebf0;
}
.OweFeeList .btn {
  width: 14vw;
  background-color: rgb(60,152,123);
  height: 8vw;
  line-height: 8vw;
  border-radius: 4vw;
  text-align: center;
  color: #fff;
  font-size: 1vw;
}
.OweFeeList .itemBox {
  margin-top: 4vh;
  padding-top: 1vh;
  box-sizing: border-box;
  padding-bottom: 1vh;
  height: 73vh;
  overflow: scroll;
  width: 100vw;
}
.bottomLineText {
  color: #333;
}
.payBtn {
  display: inline-block;
  margin-left: 4vw;
  margin-top: 8vw;
  width:92vw;
  height: 11.8vw;
  background-image: linear-gradient(51deg, #1991a3 0%, #7cb630 100%);
  border-radius: 5.9vw;
  line-height: 11.8vw;
  font-family: PingFangSC-Medium;
  font-weight: 500;
  font-size: 4.8vw;
  color: #ffffff;
  text-align: center;
}
.topBtnBox .payBtn {
  width: 100%;
}
.appletBtn {
  margin-top: 4vw;
}
</style>